import * as THREE from "three";
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import gradientVertexShader from './../shaders/gradient/vertex.glsl'
import gradientFragmentShader from './../shaders/gradient/fragment.glsl'


/**
 * Base
 */
// Debug
const gui = new dat.GUI()


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Background
 */
const plane = new THREE.PlaneGeometry(25, 25, 32, 32)
const count = plane.attributes.position.count // count of vertices
const randoms = new Float32Array(count)

for (let i = 0; i < count; i++) {
    randoms[i] = Math.random()
}

plane.setAttribute('aRandom', new THREE.BufferAttribute(randoms, 1))


// Material
const gradientMaterial = new THREE.ShaderMaterial({
    vertexShader: gradientVertexShader,
    fragmentShader: gradientFragmentShader,
    //  transparent: true,
    // wireframe: true,
    uniforms: {
        uFrequency: {
            value: new THREE.Vector2(10, 5),
        },
        uTime: {
            value: 0,
        },
        uRandom: {
            value: Math.random()
        }
    }
})

const backsideGradientMaterial = new THREE.ShaderMaterial({
    vertexShader: gradientVertexShader,
    fragmentShader: gradientFragmentShader,
    side: THREE.BackSide,
    //  transparent: true,
    // wireframe: true,
    uniforms: {
        uFrequency: {
            value: new THREE.Vector2(10, 5),
        },
        uTime: {
            value: 0,
        },
        uRandom: {
            value: Math.random()
        }
    }
})


const basicMaterial = new THREE.MeshBasicMaterial({
    color: "#ffffff",
})

// const mesh = new THREE.Mesh(plane, gradientMaterial)
// mesh.position.set(2, 20, 5)
// mesh.rotation.set(Math.PI * 1.5, 0, 0)
// scene.add(mesh)


const geometryCube = new THREE.BoxGeometry(10, 10, 10);
const cube = new THREE.Mesh(geometryCube, gradientMaterial);
cube.position.set(0, 0, -50)
scene.add(cube);

// TUBE

let tubePoints = [];

// Define points along Z axis
for (let i = 0; i < 5; i += 1) {
    tubePoints.push(new THREE.Vector3(0, 0, 2.5 * (i / 4)));
}

// Create a curve based on the points
const curve = new THREE.CatmullRomCurve3(tubePoints)
const tubeGeometry = new THREE.TubeGeometry(curve, 70, 0.02, 50, false);

// Define a material for the tube with a jpg as texture instead of plain color
const tubeMaterial = new THREE.MeshStandardMaterial({
    side: THREE.BackSide, // Since the camera will be inside the tube we need to reverse the faces
});

// Create a mesh based on tubeGeometry and tubeMaterial
const tube = new THREE.Mesh(tubeGeometry, backsideGradientMaterial);
tube.scale.set(70, 70, 500)
tube.position.set(0, 0, -50)


// Add the tube into the scene
scene.add(tube);


// fog
const fog = new THREE.Fog(0xffffff, 1, 100)
scene.fog = fog

/*
*  PolyhedronGeometry
* */
//
// const verticesOfCube = [
//     -1,-1,-1,    1,-1,-1,    1, 1,-1,    -1, 1,-1,
//     -1,-1, 1,    1,-1, 1,    1, 1, 1,    -1, 1, 1,
// ];
//
// const indicesOfFaces = [
//     2,1,0,    0,3,2,
//     0,4,7,    7,3,0,
//     0,1,5,    5,4,0,
//     1,2,6,    6,5,1,
//     2,3,7,    7,6,2,
//     4,5,6,    6,7,4
// ];
//
// const polyhedronGeometry = new THREE.PolyhedronGeometry( verticesOfCube, indicesOfFaces, 6, 2 );
//
// const polyhedron = new THREE.Mesh(polyhedronGeometry, new THREE.MeshStandardMaterial({
//     color: 0xffffff,
// }))


// gui.add(material.uniforms.uFrequency.value, 'x')
//     .name("Frequency x")
//     .min(0)
//     .max(20)
//     .step(0.01)
//
// gui.add(material.uniforms.uFrequency.value, 'y')
//     .name("Frequency Y")
//     .min(0)
//     .max(20)
//     .step(0.01)


// scene.add(polyhedron)
// console.log(polyhedron)
//
// gui
//     .add(polyhedron.position, 'x')
//     .min(-5)
//     .max(5)
//     .step(0.1)
//     .name("polyhedron position x")
//
// gui
//     .add(polyhedron.position, 'y')
//     .min(-5)
//     .max(5)
//     .step(0.1)
//     .name("polyhedron position y")
//
// gui
//     .add(polyhedron.position, 'z')
//     .min(-5)
//     .max(5)
//     .step(0.1)
//     .name("polyhedron position z")
//


/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000)
camera.position.set(0, 0, 1)
scene.add(camera)


// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enableRotate = false


/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update material
    gradientMaterial.uniforms.uTime.value = elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()



