import {createClient} from '@supabase/supabase-js'
import {RealtimeClient} from "@supabase/supabase-js";

const Supabase = class Supabase {
    constructor() {
        const key = process.env.SUPABASE_KEY
        const url = process.env.SUPABASE_URL
        this.client = createClient(url, key)
        this.realtimeTable = 'inputdata'
    }

    /*
    async getDataFromTable(table) {
        await this.client.from(table)
            .then((res) => {
                return res.data
            })
            .then(res => {
                return res
            })
    }
    */
    startListening() {
        this.client
            .from(this.realtimeTable)
            .on('*', payload => {
                window.BusBoy.trigger('rowupdate', payload.new)
            })
            .subscribe()

    }

    /* async addData(table){
         return new Promise(async (resolve, reject) => {
             await this.client
                 .from(table)
                 .insert({
                     'somevalue': value,
                     'userid': this.userid,
                 })
                 .single()
                 .then((res) => resolve(res))
         })
     }*/
    updateData() {

    }

}

export default new Supabase()