//imports
import './style.css'
import Supabase from "./services/Supabase";
import BusBoy from "./services/BusBoy";
import SoundLayer from "./layers/SoundLayer";
import * as GradienScene from "./layers/GradientPlaneLayer";
// import * as LightLayer from "./layers/LightLayer";
import ToneLayer from "./layers/ToneLayer";

//init eventbus
const BUSBOY = new BusBoy()
window.BusBoy = BUSBOY

//tone
const TONE = new ToneLayer()
TONE.playSounds()

//listen to changes
//Supabase.startListening()
window.BusBoy.on('rowupdate', ({detail}) => {

})