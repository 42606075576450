import * as Tone from 'tone'
import Supabase from "../services/Supabase";

export default class ToneLayer {
    constructor() {
        this.tone = Tone
        this.now = Tone.now()
        this.volumeX = 0
        this.synthX = null
    }

    initSoundX() {
        this.synthX = new Tone.Synth().toDestination();
        this.synthX.volume.value = this.volumeX
        //this.synthX.triggerAttack("C4", "8n", this.now)
        //synth.triggerAttackRelease("E4", "8n", this.now + 2)
        //synth.triggerAttackRelease("G4", "8n", this.now + 6)
    }

    updateSoundX(x) {
        this.synthX.volume.value = this.normalizeToDezibel(x)
    }

    soundX(volume = 1) {
        const VOLUME = this.normalizeVolume(volume)
        const synth = new Tone.Synth().toDestination();
        synth.triggerAttackRelease("C4", "8n", this.now)
        synth.triggerAttackRelease("E4", "8n", this.now + 2)
        synth.triggerAttackRelease("G4", "8n", this.now + 6)

        //const GAIN = new this.tone.Volume(VOLUME).toDestination()
        //const SYNTH = new this.tone.Synth()
        //SYNTH.volume = VOLUME
        //SYNTH.triggerAttack('C5')
        /*const SYNTH1 = new this.tone.Synth().connect(GAIN)
        SYNTH1.triggerAttackRelease("C4", "8n");*/
        //const SYNTH1 = new this.tone.Synth().connect(GAIN)
        /*const GAIN = new this.tone.Gain(volume).toDestination()
        const SYNTH1 = new this.tone.Synth().connect(GAIN)
        SYNTH1.triggerAttackRelease("C4", "8n");*/

    }

    normalizeVolume(val) {
        const minValue = Math.max(0, val)
        return Math.min(minValue / 4, 1)
    }

    normalizeToDezibel(val) {
        console.log(this.normalizeVolume((65 * val - 25)) * 65)
        return this.normalizeVolume((65 * val - 25)) * 65
    }

    playSounds() {
        Supabase.startListening()
        this.initSoundX()
        window.BusBoy.on('rowupdate', ({detail}) => {
            this.updateSoundX(detail.acceleration_y)
        })
    }

    log() {
        console.log(this.tone)
    }
}